// Companies no applying for KYC
export const KYC_STATUS_NONE = 0

// KYC form not submitted, aka “clicking around“
export const KYC_STATUS_ZERO = 1

export const KYC_STATUS_REJECTED = 2

export const KYC_STATUS_PENDING = 3

export const KYC_STATUS_APPROVED = 5

export const KYC_STATUS_FRAUD_SUSPECTED = 6
